<!-- TODO make this work -->
<!--<mat-icon matSuffix="">{{formControl.value}}</mat-icon>-->

<mat-icon *ngIf="formControl.value">{{formControl.value}}</mat-icon>

<input
  [formControl]="formControl"
  [formlyAttributes]="field"
  [matAutocomplete]="auto"
  matInput
  type="text"
/>
<!--<span matPrefix>+1 &nbsp;</span>-->

<mat-autocomplete
  #auto="matAutocomplete"
  [autoActiveFirstOption]="false"
>
  <mat-option
    *ngFor="let icon of (filteredIcons$|async); trackBy: trackByIndex"
    [value]="icon"
  >
    <mat-icon>{{icon}}</mat-icon>
    <span>{{icon}}</span>
  </mat-option>
</mat-autocomplete>
