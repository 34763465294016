<right-panel *ngIf="timelineEntries$|async as timelineEntries">
  <div class="wrapper">
    <div
      class="inner-wrapper"
      [@standardList]="timelineEntries.length"
    >
      <div
        *ngIf="!timelineEntries.length"
        class="no-tasks-msg"
      >
        {{T.F.TIMELINE.NO_TASKS|translate}}
      </div>

      <div
        class="row"
        *ngFor="let row of timelineEntries; trackBy: trackByFn"
      >
        <div
          class="col time"
          [class.hasTime]="!row.isHideTime"
        >
          <div *ngIf="!row.isHideTime">
            <div>
              {{ row.start <= now ? (T.F.TIMELINE.NOW|translate) :
              (row.start|roundDuration|date:'HH:mm')}}
            </div>
            <!--            <div *ngIf="row.start>= tomorrow" class="date">-->
            <!--              {{ row.start <= now ? (T.F.TIMELINE.NOW|translate) :-->
            <!--              (row.start|date:'shortDate')}}-->
            <!--            </div>-->
          </div>
          <div
            *ngIf="(row.type ===TimelineViewEntryType.Task || row.type ===TimelineViewEntryType.SplitTask) && row.data.id !== (taskService.currentTaskId$|async)"
            class="up-down"
          >
            <button
              mat-icon-button
              (click)="moveUp(row.data)"
            >
              <mat-icon>arrow_upward</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="moveDown(row.data)"
            >
              <mat-icon>arrow_downward</mat-icon>
            </button>
          </div>

          <div
            *ngIf="(row.type ===TimelineViewEntryType.ScheduledTask)"
            class="scheduled-extra-btn"
          >
            <button
              mat-mini-fab
              (click)="editTaskReminder(row.data)"
              color="primary"
            >
              <mat-icon>alarm</mat-icon>
            </button>
          </div>
        </div>
        <div
          class="col right"
          [ngSwitch]="row.type"
        >
          <task
            *ngSwitchCase="TimelineViewEntryType.Task"
            showParentTitle="yes"
            [task]="row.data"
          ></task>
          <task
            *ngSwitchCase="TimelineViewEntryType.ScheduledTask"
            showParentTitle="yes"
            [task]="row.data"
          ></task>
          <task
            *ngSwitchCase="TimelineViewEntryType.SplitTask"
            showParentTitle="yes"
            class="split-task"
            [task]="row.data"
          ></task>

          <div
            *ngSwitchCase="TimelineViewEntryType.SplitTaskContinuedLast"
            class="task-continued last"
          >
            <div class="title">{{row.data.title}} ({{row.data.index + 2}})</div>
            <div class="time-to-go">
              continued ~{{row.data.timeToGo|roundDuration|msToString}}
            </div>
          </div>

          <div
            *ngSwitchCase="TimelineViewEntryType.SplitTaskContinued"
            class="task-continued"
          >
            <div class="title">{{row.data.title}} ({{row.data.index + 2}})</div>
            <div class="time-to-go">
              continued ~{{row.data.timeToGo|roundDuration|msToString}}
            </div>
          </div>

          <!--          <timeline-custom-event-->
          <!--            *ngSwitchCase="TimelineViewEntryType.CustomEvent"-->
          <!--            [event]="row.data"-->
          <!--          ></timeline-custom-event>-->

          <timeline-calendar-event
            *ngSwitchCase="TimelineViewEntryType.CalendarEvent"
            [calendarEvent]="row.data"
            [matTooltip]="'Add event as task'"
          ></timeline-calendar-event>

          <timeline-task-repeat-projection
            [title]="T.F.TIMELINE.TASK_PROJECTION_INFO|translate"
            *ngSwitchCase="TimelineViewEntryType.ScheduledRepeatTaskProjection"
            [repeatCfg]="row.data"
          ></timeline-task-repeat-projection>

          <div
            *ngSwitchCase="TimelineViewEntryType.WorkdayStart"
            [title]="row.start|date:'medium'"
            class="work-start"
          >
            {{T.F.TIMELINE.START|translate}}
          </div>

          <div
            *ngSwitchCase="TimelineViewEntryType.WorkdayEnd"
            [title]="row.start|date:'medium'"
            class="work-end"
          >
            {{T.F.TIMELINE.END|translate}}
          </div>

          <div
            *ngSwitchCase="TimelineViewEntryType.LunchBreak"
            class="lunch-break"
          >
            {{T.F.TIMELINE.LUNCH_BREAK|translate}}
          </div>

          <div
            *ngSwitchCase="TimelineViewEntryType.DayCrossing"
            class="day-crossing separator"
          >
            {{row.start|date:'d.M.'}} <span class="day">{{row.start|date:'(EEE)'}}</span>
          </div>

          <div *ngSwitchDefault>------------------</div>
        </div>
      </div>
    </div>
  </div>
</right-panel>
